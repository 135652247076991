"use client"
import { useEffect } from 'react'
import { App as CapacitorApp } from '@capacitor/app';

const BackButtonListener = () => {

	useEffect(()=> {
		CapacitorApp.addListener('backButton',({canGoBack})=>{
			if(!canGoBack){
				CapacitorApp.exitApp()
			}else{
				window.history.back()
			}
		})
	},[])
	
  return null

}

export default BackButtonListener
