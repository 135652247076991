import { Loader } from 'lucide-react'

const PageLoader = () => {
    return (

        <div className='bottom-0 items-center fixed animate-pulse flex gap-2 right-0 z-50 pb-3 pr-3'>
            <Loader size={30} className='animate-spin text-blue-400' />
            <p className='font-medium text-sm'>Loading...</p>
        </div>

    )
}

export default PageLoader