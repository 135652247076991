import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { X } from 'lucide-react'

const showToastVariants = cva(
  'group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-4 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full',
  {
    variants: {
      variant: {
        default: 'border bg-background text-foreground',
        destructive:
          'destructive group border-destructive bg-destructive text-destructive-foreground',
        success: 'success group border-green-600 bg-green-600 text-white',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface ShowToastProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof showToastVariants> {
  title?: string
  description: string
  action?: React.ReactNode
}

const ShowToast = React.forwardRef<HTMLDivElement, ShowToastProps>(
  ({ className, variant, title, description, action, ...props }, ref) => {
    const [close, setClose] = React.useState(false)
    React.useEffect(() => {
      setTimeout(() => {
        setClose(true)
      }, 5000)
    }, [])
    const handleClose = () => setClose(true)
    return close ? null : (
      <div className="fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]">
        <div className={showToastVariants({ variant, className })} ref={ref} {...props}>
          <div className="grid gap-1">
            {title && <h4>{title}</h4>}
            {description && <p>{description}</p>}
          </div>
          {action}
          <X onClick={handleClose} className="cursor-pointer" />
        </div>
      </div>
    )
  },
)

export default ShowToast
