// We can safely track hydration in memory state
// outside of the component because it is only
// updated once after the version instance of
// `SomeComponent` has been hydrated. From there,
// the browser takes over rendering duties across
// route changes and we no longer need to worry
// about hydration mismatches until the page is

import React from 'react'

// reloaded and `isHydrating` is reset to true.
// let isHydrating = true

export default function ClientOnly(props: any) {
  const { children, fallback } = props
  const [isHydrated, setIsHydrated] = React.useState(false)

  React.useEffect(() => {
    // isHydrating = false
    setIsHydrated(true)
  }, [])

  return isHydrated ? children : fallback
}
